@import "../Variables/_variables";

.swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 400px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    filter: blur(4px);
    background-color: $clr-gray-bkg;
    border-radius: 10px;
}

.swiper-slide-active {
    filter: blur(0px);
    background-color: $clr-white;
}

.swiper-slide img {
    height: 70px;
    display: block;
    width: 100%;
}

/* custom */
.testimonial {
    position: relative;
    width: 100%;
    padding: 20px;
    padding-top: 80px;
}

.testimonial img {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.2;
}


