@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&family=Satisfy&display=swap");

$clr-btn: #932432;
$clr-gray-bkg: #f3f3f3;
$clr-dark-bkg: #202020;
$clr-white: #fff;
$clr-dark: #000;
$clr-shadow: #ccc;

$border-radius-btn: 5px;
$border-radius-img: 20px;

$font-family-logo: "Satisfy", cursive;
$font-family-default: "Poppins", sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: $font-family-default;
}
