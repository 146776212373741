@import "./Variables/_variables";

html {
  scroll-behavior: smooth;
}

section {
  // box-shadow: inset 2px 2px $clr-shadow;
}

.section-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-header {
  font-weight: bold;
  font-size: 2em;
  border-bottom: 2px solid $clr-shadow;
  margin: 20px;
}