@import "../Variables/_variables";

.swiper-container {
    
    width: 100%;
    height: 100%;
}

.swiper1 {

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: $clr-gray-bkg;
        filter: blur(0px);
        width: auto;
    }

    .swiper-slide img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
    }

}




