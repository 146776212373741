@import "../Variables/_variables";

.community {
  // background-color: $clr-gray-bkg;  
}

.community-outer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.community-inner-container {
    position: relative;
  padding: 20px;;
  img {
    height: 600px;

    width: 100%;
    object-fit: cover;
    object-position:50% 50%;
  }
}

.community-img {
  width: 100%;
  border-radius: $border-radius-img;
  box-shadow: 3px 3px 5px 6px $clr-shadow;
}

.caption {
  position: absolute;
  width: 100%;
  height: 110px;
  bottom: 0px;
  left: 0px;
  color: $clr-dark;
  background: $clr-shadow;
  text-align: center;
  padding: 10px;

  a {
    color: $clr-btn;
  }
}

@media screen and (max-width: 1100px) { 
  .community-inner-container {
    img {
      height: 400px;
    }
  }
}

@media screen and (max-width: 900px) {
  .community-outer-container {
    display: grid;
    gap: 30px;
  }

  .community-inner-container {
    margin: 0 20px;
  }
}

@media screen and (max-width: 660px) {
  .community-inner-container {
    padding: 50px;
  }
}

@media screen and (max-width: 410px) {
  .community-outer-container {
    padding-bottom: 50px;
  }

  .caption {
    height: 160px;
  }
}
