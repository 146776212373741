@import "../Variables/_variables";

.reviews {
  background-color: $clr-gray-bkg;
  padding-bottom: 20px;
  // // The following icon is made by https://www.freepik.com
  // background-image: url("./img/quote.jpg");
  // background-repeat: no-repeat;
  // background-size: auto;
  // background-blend-mode:darken;
  // background-position: center;
}

.review-comment {
  font-style: italic;
}

.review-author {
  font-weight: bold;
  font-style: normal;
  text-align: right;
}

.review-author::before {
  content: "\2014\0020";
}

// .review-container {
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap: 20px;
//   padding: 30px;
// }

// .review-item {
// }

// .review-text {
//   display: block;
//   font-size: 1.5em;
// }

// .review-text::before {
//   content: "\201c";
//   font-size: 3rem;
// }

// .review-text::after {
//   content: "\201d";
//   top: 10px;
//   left: -40px;
//   font-size: 3rem;
// }

// .review-img {
//   border-radius: $border-radius-img;
//   width: 100%;
//   box-shadow: 3px 3px 5px 6px $clr-shadow;
// }

.msg {
  a {
    color: $clr-btn;
    text-decoration: underline;
    font-weight: bold;
  }
}

// @media screen and (max-width: 1100px) {
//   .review-container {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }

// @media screen and (max-width: 830px) {
//   .review-container {
//     grid-template-columns: repeat(2, 1fr);
//     gap: 10px;
//     padding: 20px;
//   }
// }

// @media screen and (max-width: 400px) {
//   .review-container {
//     grid-template-columns: repeat(1, 1fr);
//   }
// }
