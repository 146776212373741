@import "../Variables/_variables";

.galleries {
  background-color: $clr-gray-bkg;
  color: $clr-dark;
  padding-bottom: 20px;
}

.msg {
  text-align: center;
  padding: 0 20px;
}

// .gallery-container {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
//   gap: 40px;
//   padding: 20px;
// }

.gallery-item {
}

// .gallery-image {
//   width: 100%;
//   border-radius: $border-radius-img;
//   box-shadow: 3px 3px 5px 6px $clr-shadow;
// }

// .item7 {
//   grid-column: span 2;
//   grid-row: span 2;
// }

// @media screen and (max-width: 1200px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   }
// }

// @media screen and (max-width: 1030px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//     gap: 40px;
//   }
// }

// @media screen and (max-width: 870px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//   }
// }

// @media screen and (max-width: 720px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
//     gap: 20px;
//   }
// }

// @media screen and (max-width: 530px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
//   }
// }

// @media screen and (max-width: 470px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
//   }

//   .item0 {
//     display: none;
//   }
// }

// @media screen and (max-width: 310px) {
//   .gallery-container {
//     grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
//   }

//   .item0 {
//     display: unset;
//   }

//   .item7 {
//     grid-column: span 1;
//     grid-row: span 1;
//   }
// }
