@import "../Variables/_variables";

.social-icons {
  background: $clr-dark-bkg;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    padding: 0 20px;
    a {
      background-color: $clr-white;
      background-color: $clr-btn;
      opacity: 0.8;
      border-radius: 80%;
    }
  }
}
