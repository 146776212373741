@import "../Variables/_variables";

.btn-primary {
  background-color: $clr-btn;
  color: $clr-white;
  font-weight: bold;
  padding: 0 5px;
  border: none;
  border-radius: $border-radius-btn;
  font-size: 1em;
}

.btn-primary:hover {
  cursor: pointer;
  background-color: #932412;
  transition: background-color ease 0.3s;
}
