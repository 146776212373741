@import "../Variables/_variables";

.landing {
  right: 0;
  width: 100%;
  height: 100vh;
  background: $clr-dark-bkg;
  transition: 0.5s;
  z-index: 2;

  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; // this will fit the video to full screen
    opacity: 0.8;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #03a9f4;
  mix-blend-mode: overlay; // this is similar to opacity
}

.cta {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 5;
  top: 30%;
  left: 10%;
  color: $clr-white;

  h2 {
    font-size: 5em;
    font-weight: 800;

    line-height: 1em;
    text-transform: uppercase;
  }
  h3 {
    font-size: 4em;
    font-weight: 700;

    line-height: 1em;
    text-transform: uppercase;
  }
  p {
    font-size: 1.1em;
    padding-left: 10px;
    border-left: 4px solid white;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
  }

  a {
    display: inline-block;
    font-size: 1em;
    background: $clr-btn;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    color: $clr-gray-bkg;
    letter-spacing: 2px;
    transition: 0.2s;
    border-radius: $border-radius-btn;
  }
  a:hover {
    letter-spacing: 6px;
  }
}

@media screen and (max-width: 1200px) {
  .cta {
    h2 {
      font-size: 3.8em;
    }
    h3 {
      font-size: 3.3em;
    }
  }
}

@media screen and (max-width: 1000px) {
  .cta {
    h2 {
      font-size: 3.5em;
    }
    h3 {
      font-size: 2.5em;
    }
  }
}

@media screen and (max-width: 840px) {
  .cta {
    h2 {
      font-size: 3em;
    }
    h3 {
      font-size: 2em;
    }
    p {
      max-width: fit-content;
    }
  }
}

@media screen and (max-width: 730px) {
  .cta {
    h2 {
      font-size: 2.5em;
    }
    h3 {
      font-size: 1.5em;
    }
  }
}

@media screen and (max-width: 620px) {
  .cta {
    h2 {
      font-size: 2.2em;
    }
    h3 {
      font-size: 1.2em;
    }
  }
}

@media screen and (max-width: 530px) {
  .cta {
    h2 {
      font-size: 2em;
    }
    h3 {
      font-size: 1em;
    }
  }
}

@media screen and (max-width: 475px) {
  .cta {
    h2 {
      font-size: 1.5em;
    }
  }
}

@media screen and (max-width: 360px) {
  .cta {
    h2 {
      font-size: 1.2em;
      font-weight: 500;
    }
    h3 {
      font-size: 0.9em;
      font-weight: 300;
    }
    p {
      font-size: 0.8em;
    }
    a {
      font-size: 0.8em;
    }
  }
}
