@import "../Variables/_variables";

.footer {
  background-color: $clr-dark-bkg;
}

footer {
  color: $clr-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  padding-top: 20px;
}

.follow {
  font-size: 1.2em;
  border-bottom: 2px solid $clr-white;
  margin-bottom: 1em;
}

.call-to-action {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: $clr-white;
  }

  li {
    padding: 1.2em;
  }
}

.footer-cta::after {
  content: "✨";
}

.footer-cta::before {
  content: "✨";
}

.footer-cta a:hover {
  letter-spacing: 0.1em;
}

.back-to-top {
  display: flex;
  flex-direction: column;
  text-align: center;
  a {
    color: $clr-shadow;
  }
  img {
    transform: rotate(90deg);
    height: 50px;
  }
  :hover {
    img {
      height: 54px;
    }
  }
}

.copyright {
  color: $clr-shadow;
  text-align: end;
  margin: 0;
  padding: 0;
  font-size: 0.7em;
}
