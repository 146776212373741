@import "../Variables/_variables";

.is-active {
  font-weight: bold;
  border-bottom: 2px solid $clr-white;
}

nav {
  position: relative;
  // align spacing
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  z-index: 100;
  // background-color: $clr-dark-bkg;

  ul {
    display: flex;
    width: 55%;
    justify-content: space-around;

    li {
      list-style: none;

      a {
        color: $clr-white;
        // text-transform: uppercase;
      }
    }
  }
}

.sticky {
    // sticky nav
    position: fixed;
    width: 100%;
    background-color: $clr-dark;
}

#nav-links li {
  a::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: $clr-white;
    transition: width 0.3s;
  }

  a:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

.logo {
  cursor: pointer;
  a {
    font-family: $font-family-logo;
    color: $clr-white;
    font-weight: bold;
    font-size: 2em;
  }
  img {
    height: 100px;
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  div {
    width: 25px;
    height: 3px;
    background-color: $clr-white;
    margin: 5px;
    // for hamburger animation
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 1250px) {
  nav ul {
    // push the nav links below actual nav bar
    position: absolute;
    right: 0px;
    height: 100vh;
    top: 0vh;
    // and style it the way you want
    background-color: $clr-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // move the nav links off the screen
    // if we want these navs to show, we just translateX back to zero
    transform: translateX(100%);
    // just for animation
    transition: transform 0.5s ease-in;
    li {
      padding: 15px;
      opacity: 0;
    }
  }

  // we don't want to scroll so we hide the nav links
  body,
  html {
    overflow-x: hidden;
  }

  .hamburger {
    display: block;
  }
  .logo {
    z-index: 1000;
  }
}

.hamburger-activated {
  transform: translateX(0%);
}

// animation for nav links
@keyframes animateNavLinks {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
// change the hamburger after it is toggled
.toggled .line1 {
  transform: rotate(-45 * 9deg) translate(-5px, 6px);
  transition: all;
}
.toggled .line2 {
  opacity: 0;
}
.toggled .line3 {
  transform: rotate(45 * 9deg) translate(-5px, -6px);
}
