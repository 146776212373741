@import "../Variables/_variables";

.book-now {
    background-image: url("../img/background-logo3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode:lighten;
    padding-bottom: 2em;
    color: $clr-white;
}

.quote {
    display: block;
    font-size: 1.1em;
}

.quote::before {
    content: "\201c";
    font-size: 3rem;
}

.quote::after {
    content: "\201d";
    top: 10px;
    left: -40px;
    font-size: 3rem;
}

.book-now-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.book-now-link {
    background-color: $clr-btn;
    color: $clr-white;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: $border-radius-btn;
    font-size: 1em;

    a {
        color: $clr-white;
    }
}

.book-now-link:hover {
    cursor: pointer;
    background-color: #932412;
    transition: background-color ease 0.3s;
}

@media screen and (max-width: 1030px) {
    .quote {
        font-size: 1.3em;
    }
}

@media screen and (max-width: 800px) {
    .quote {
        font-size: 1em;
    }
}

@media screen and (max-width: 362px) {
    .quote::after, .quote::before{
        font-size: 1em;
    }
}
