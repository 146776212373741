@import "../Variables/_variables";

.about-me {
  background-color: $clr-gray-bkg;
  background-image: url("../img/background-logo1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // background-blend-mode: lighten;
  background-blend-mode:overlay;
}

.about-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding: 0 40px;
}

.about-img {
  width: 100%;
  border-radius: $border-radius-img;
  box-shadow: 3px 3px 5px 6px $clr-shadow;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  grid-column: span 3;
  padding: 40px;
  font-size: 1.2em;
  line-height: 2em;
}

.about-item0 {
  place-content: start start;
}

.about-item1 {
  place-content: end end;
}

.about-item2 {
  place-content: start start;
  padding-bottom: 40px;
}

@media screen and (max-width: 900px) {
  .about-content {
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 760px) {
  .about-content {
    padding: 30px;
    line-height: 1.2em;
  }
}

@media screen and (max-width: 570px) {
  .about-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 20px;
  }

  .about-content {
    order: 1;
    padding: 20px;
    margin-bottom: 20px;
  }

  .about-img {
    margin-left: 50%;
  }
}
