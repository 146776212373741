@import "../Variables/_variables";

.services {
    background-color: $clr-dark-bkg;
    color: $clr-white;
    margin-bottom: -90px;
}

.service-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer-container {
    display: flex;
    flex-direction: column;
    border: 2px solid $clr-btn;
    // margin-bottom: -200px;
    background-color: $clr-dark-bkg;
    // height: 300px;
    width: 50%;
}

.upper-container {
    margin: 10px;
    margin-bottom: 40px;
}

.services-container {
    display: flex;
    flex-direction: column;
}

.service {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    text-transform: uppercase;
    border-bottom: 1px dotted $clr-gray-bkg;
}

.book-now-link {
    
    text-align: center;

    a {
        color: $clr-white;
    }
}

.dummy-cont {
    height: 100px;
    background-color: $clr-gray-bkg;
}

@media screen and (max-width: 900px) {
    .outer-container {
        width: 80%;
    }
}

@media screen and (max-width: 400px) {
    .outer-container {
        width: 90%;
    }
}